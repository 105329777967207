import Vue from "vue";
import router from "@/router";
import App from "@/App";
import VueAxios from "vue-axios";
import axios from "@/axios-init";
import dateFormat from "@/utils/dateFormat";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import store from "@/store";
import { loadUserInfo } from "@/store";
import { Message, Upload, Dialog, Button, Input } from "element-ui";
import headUser from "@/components/headUser";
Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(dateFormat, { format: "yyyy-MM-dd HH:mm" });
Vue.use(Viewer);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Input);
Vue.prototype.$message = Message;

loadUserInfo().then(function() {
  new Vue({
    el: "#app",
    data: () => ({ page: "home" }),
    store,
    components: {
      headUser,
      App
    },
    router
  });
});
