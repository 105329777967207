import axios from "@/axios-init";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    user: {
      img: "",
      name: "",
      account: "",
      role: ""
    }
  },
  getters: {
    user: state => state.user
  },
  mutations: {
    user(state, user) {
      state.user = user;
    }
  }
});

export const loadUserInfo = async () => {
  try {
    let response = await axios.get("member/user/getuser");
    if (response.data) {
      store.commit("user", {
        img: axios.defaults.baseURL.replace(/\/*$/, "") + response.data.Image,
        name: response.data.Name,
        account: response.data.WXUnionid,
        role: response.data.GroupID
      });
    } else {
      store.commit("user", {
        img: "",
        name: "",
        account: "",
        role: ""
      });
    }
  } catch (error) {
    console.error(error);
  }
};
export default store;
