<!--
 * @Author: Xuxinxiang
 * @Date: 2019-11-18 11:22:52
 * @LastEditTime: 2019-12-03 10:11:31
 -->
<template>
  <div class="head-user-right">
    <div class="login_btn" v-if="!user.account">
      <a href="/member/user/login">登录</a> /
      <a href="/member/user/login">注册</a>
    </div>
    <div class="user_img_name" v-else>
      <div class="box">
        <img :src="user.img" />
        <span v-text="user.name"></span>
      </div>
      <div class="controller_pop">
        <ul>
          <li>
            <a href="/#/message">我的消息</a>
          </li>
          <li>
            <a href="/#/submitList">我提交的</a>
          </li>
          <li class="vc" v-if="user.role === 2">
            <a href="/#/adminVc">管理员验证</a>
          </li>
          <li class="feed">
            <a href="/#/feedback">反馈</a>
          </li>
          <li class="logout">
            <a @click="logOut" href="javascript:;">退出</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "@/axios-init";
export default {
  name: "headUser",
  computed: mapState(["user"]),
  methods: {
    logOut() {
      this.$http
        .get("member/user/logout")
        .then(response => {
          if (response.data.state) {
            location.href =
              axios.defaults.baseURL.replace(/\/*$/, "") + "/member/user/login";
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="less"></style>
