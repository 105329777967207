import axios from "axios";
import qs from "qs";

export default axios.create({
  baseURL:
    process.env.NODE_ENV === "production" ? "/" : "https://huanbaoditu.com/",
  withCredentials: true,
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
  // headers: { "Content-Type": "application/json" },
  transformRequest: [data => qs.stringify(data)]
});

export const formDataAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" ? "/" : "https://huanbaoditu.com/",
  withCredentials: true
});
