<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style lang="less">
[v-cloak] {
  display: none;
}
// 公共单选样式
.public-radio {
  position: relative;
  margin-right: 10px;
  display: flex;
  align-items: center;
  input {
    width: 14px;
    height: 14px;
    opacity: 0;
    &:checked + span {
      &::before {
        // background-color: #408c72;
        // border: 1px solid #408c72;
        background-color: #206ab0;
        border: 1px solid #206ab0;
        border-radius: 100%;
        overflow: hidden;
      }
      &::after {
        content: "";
        position: absolute;
        // width: 3px;
        // height: 7px;
        // top: 4px;
        // left: -10px;
        width: 3px;
        height: 6px;
        top: 5px;
        left: -10px;
        border: 2px solid #fff;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
      }
    }
  }
  span {
    font-size: 14px;
    color: #777777;
    padding-left: 6px;
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: -14px;
      top: 3px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 1px solid #979797;
      box-sizing: border-box;
      border-radius: 100%;
      overflow: hidden;
    }
  }
}
// 公共按钮样式
.public-btn {
  display: block;
  width: 178px;
  height: 40px;
  margin: 40px auto 40px;
  border-radius: 20px;
  background-color: #408c72;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: #fffefe;
  cursor: pointer;
}
// 隐藏高德地图logo和copyRight
.amap-logo {
  display: none !important;
}
.amap-copyright {
  opacity: 0;
}
// 重置地区选择器样式
.distpicker-address-wrapper select {
  height: 34px !important;
  font-size: 14px !important;
  margin-top: 10px;
  padding: 2px 4px !important;
}
// 重置textarea样式
.el-textarea__inner {
  border: 1px solid #dadadb;
  background-color: #f8f9fd;
}
.el-textarea .el-input__count {
  background: #f8f9fd;
}
//自定义图标
.icon {
  position: relative;
  display: inline-block;
  background: no-repeat center center;
  &.icon-point {
    width: 20px;
    height: 26px;
    background-image: url(assets/icon/icon-point.png);
  }
  &.icon-infoWindowPoint {
    width: 16px;
    height: 20px;
    background-image: url(assets/icon/icon-infoWindowPoint.png);
  }
  &.icon-infoWindowClose {
    width: 10px;
    height: 10px;
    background-image: url(assets/icon/icon-infoWindowClose.png);
    background-size: 100% 100%;
  }
}

.b {
  position: relative;
  display: inline-block;
  background: no-repeat center center;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-size: 100%;
  &.b-box {
    background-image: url(assets/icon/b-box.png);
  }
  &.b-didian {
    background-image: url(assets/icon/b-didian.png);
  }
  &.b-fund {
    background-image: url(assets/icon/b-fund.png);
  }
  &.b-img {
    background-image: url(assets/icon/b-img.png);
  }
  &.b-miaoshu {
    background-image: url(assets/icon/b-miaoshu.png);
  }
  &.b-mingcheng {
    background-image: url(assets/icon/b-mingcheng.png);
  }
  &.b-point {
    background-image: url(assets/icon/b-point.png);
  }
  &.b-shijian {
    background-image: url(assets/icon/b-shijian.png);
  }
}
.f {
  position: relative;
  display: inline-block;
  background: no-repeat center center;
  width: 28px;
  vertical-align: middle;
  &.f-didian {
    background-image: url(assets/icon/f-didian.png);
  }
  &.f-falvshouduan {
    background-image: url(assets/icon/f-falvshouduan.png);
  }
  &.f-file {
    background-image: url(assets/icon/f-file.png);
  }
  &.f-img {
    background-image: url(assets/icon/f-img.png);
  }
  &.f-jubaotujing {
    background-image: url(assets/icon/f-jubaotujing.png);
  }
  &.f-miaoshu {
    background-image: url(assets/icon/f-miaoshu.png);
  }
  &.f-mingcheng {
    background-image: url(assets/icon/f-mingcheng.png);
  }
  &.f-shijian {
    background-image: url(assets/icon/f-shijian.png);
  }
  &.f-wuranleixing {
    background-image: url(assets/icon/f-wuranleixing.png);
  }
  &.f-wuranxingwei {
    background-image: url(assets/icon/f-wuranxingwei.png);
  }
  &.f-xiangguanwenzhang {
    background-image: url(assets/icon/f-xiangguanwenzhang.png);
  }
  &.f-zerenzhuti {
    background-image: url(assets/icon/f-zerenzhuti.png);
  }
  &.f-zhenggaijichufacuoshi {
    background-image: url(assets/icon/f-zhenggaijichufacuoshi.png);
  }
  &.f-zhengzhishouduan {
    background-image: url(assets/icon/f-zhengzhishouduan.png);
  }
}

.i {
  position: relative;
  display: inline-block;
  background: no-repeat center center;
  width: 23px;
  height: 23px;
  vertical-align: middle;
  background-size: 100%;
  &.icon-weizhengzhi {
    background-image: url(assets/icon/icon-weizhengzhi.png);
  }
  &.icon-wurandian {
    background-image: url(assets/icon/icon-wurandian.png);
  }
  &.icon-yizhengzhi {
    background-image: url(assets/icon/icon-yizhengzhi.png);
  }
  &.icon-zhengzhizhong {
    background-image: url(assets/icon/icon-zhengzhizhong.png);
  }
}
.viewer-title {
  color: #fff;
  font-size: 16px;
}
</style>
