/*
 * @Author: Xuxinxiang
 * @Date: 2019-11-06 09:33:24
 * @LastEditTime: 2019-12-02 14:10:19
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/pages/index")
  },
  {
    path: "/adminVc",
    component: () => import("@/pages/adminVc")
  },
  {
    path: "/submitList",
    component: () => import("@/pages/submitList")
  },
  {
    path: "/message",
    component: () => import("@/pages/message")
  },
  {
    path: "/participate",
    component: () => import("@/pages/participate")
  },
  {
    path: "/submitInfo",
    props: route => ({ id: route.query.id, type: route.query.type }),
    component: () => import("@/pages/submitInfo")
  },
  {
    path: "/verification",
    props: route => ({ id: route.query.id }),
    component: () => import("@/pages/verification")
  },
  {
    path: "/feedback",
    component: () => import("@/pages/feedback")
  }
];

const router = new VueRouter({
  routes
});

export default router;
